$(document).ready(function() {
    $('.sld_cover').on('init', function(e, slick) {
        var $firstAnimatingElements = $('div.wrap:first-child').find('[data-animation]');
        doAnimations($firstAnimatingElements);    
    });
    $('.sld_cover').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
              var $animatingElements = $('div.wrap[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
              doAnimations($animatingElements);    
    });
    $('.sld_cover').slick({
        dots: true,
		infinite: true,
		speed: 1000,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 3000,
		autoplay: true,
		arrows: false,
		useTransform: true,
		
		responsive: [
		{
		  breakpoint: 560,
		  settings: {
			dots: false,
		  }
		}
	  ]
    });
    function doAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
                $this.removeClass($animationType);
            });
        });
    }
});

$('.info_testimonials_sld .block_slider_reviews').slick({
	dots: false,
	infinite: true,
	speed: 500,
	autoplaySpeed: 3000,
	autoplay: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
});

$(function(){ 
	var buttons = $(".buttons");
	var calls = $(".calls_scroll");
	fimenu();
	
    function fimenu(e){
		var scrolltop = $(this).scrollTop();
		
		if(scrolltop > 100){
			buttons.slideDown();
			calls.show();
			//$(".navigation_container").addClass("navigation_height");
			
		}else{
			buttons.slideUp();
			calls.hide();
			//$(".navigation_container").removeClass("navigation_height");
			$(".menu_catalog").removeClass("opens");
			$(".overlay_content_from_menu").removeClass("open");
		}
	}
	
	$(document).on("scroll", fimenu);
	
	buttons.on("click", function(){
		$("html, body").animate({
			scrollTop: 0
		}, 2000);
	}); 
	
	$(document).on("scroll", fimenu);
	
	$(function() {
		var burgerMenu = $(".navbar-header .menu-trigger");
		var content = $(".burger-menu");
		
		burgerMenu.on("click", function(e){
			e.preventDefault();
			content.toggleClass("active");
			$(".overlay_content_from_menu").addClass("open");
		});
		
		$(".overlay_content_from_menu").on("click", function(e){
			$(".overlay_content_from_menu").removeClass("open");
			content.removeClass("active");
		});
	});
});

$("[data-fancybox]").fancybox({
	speed : 330,
	infobar : true,
	buttons : false,
	protect : true,
});